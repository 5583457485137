import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LoaderModule } from '@x/common/loader/loader.module';
import { InputLoaderComponent } from './input-loader.component';

@NgModule({
  declarations: [InputLoaderComponent],
  imports: [CommonModule, LoaderModule],
  exports: [InputLoaderComponent],
})
export class InputLoaderModule {}
